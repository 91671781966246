$tm-color-blue: #1da1f2;
$tm-color-orange: #ea8804;
$tm-color-green: #36b37e;
$tm-color-red: #ff5e5e;
$tm-color-purple: #551fff;

$tm-color-medium-blue: #eaf9ff;

$tm-color-light-blue: #f7f9fc;
$tm-color-light-orange: #fff2e9;
$tm-color-light-green: #ebf7f2;
$tm-color-light-red: #ffefef;
$tm-color-light-purple: #ebe7f7;

$tm-color-text-primary: #1f3965;
$tm-color-text-secondary: #6b82ab;

$tm-color-dark-gray: #dbe2ea;
$tm-color-light-gray: #f2f4f8;
$tm-color-white: #ffffff;
